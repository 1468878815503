import './App.css';
import * as React from "react";
import { Routes, Route, Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import TeaTree from './components/images/productos/Aloe-Vera-Tea-Tree-Bottle.png'
import FichaTe from './components/fichas/te.pdf'
import AloeVera from './components/images/productos/Aloe-Vera-Hyaluronic-Ac.png'
import FichaHialuronico from './components/fichas/hialuronico.pdf'
import Lavanda from './components/images/productos/Lavender-Bottle.png'
import FichaLavanda from './components/fichas/lavanda.pdf'
import Arnica from './components/images/productos/Chamomile-Arnica-Bottle-OK.png'
import FichaArnica from './components/fichas/arnica.pdf'
import Almendra from './components/images/productos/Sweet-Almond-Bottle.png'
import FichaAlmendra from './components/fichas/almendra.pdf'

function App() {

  function Home(){
    return(
        <>
        <nav>
          <Link className="hover:text-yellow-500" to="/productos">Productos</Link>
          <Link className="hover:text-yellow-500" to="/conferencias">Conferencias</Link>
        </nav>
        <header className="App-header">
            <p className="text-5xl md:text-9xl text-yellow-500 animate-pulse">
                Bienvenido a Bienestar con PRETTAN
            </p>
        </header>
        
        </>
    )
}

function Productos(){
  return(
    <div className="background ">
      <nav>
        <Link className="hover:text-yellow-500" to="/">Home</Link>
        <Link className="hover:text-yellow-500" to="/conferencias">Conferencias</Link>
      </nav>
      <h1 className="background mt-9 text-2xl text-yellow-500 md:text-7xl font-bold">Te invitamos a conocer nuestros productos</h1>

      <section className="flex flex-wrap content-evenly justify-items-center">
        {/* Ficha de Producto Aloe Vera con Arbol del Te   */}
        <div className="cuadro rounded-lg">
          <div>
            <div >
              <img src={TeaTree} className="justify-items-center" alt="Gel Aloe Vera con Lavanda"></img>
            </div>
            <div className="textocuadrado">
              <h2 className="text-yellow-500 text-3xl"> <b>Aloe Vera & Árbol del Té gel </b> </h2>
              <h4>Producto de cuidado en casa, recomendado para el apoyo de pieles secas y con signos de causados por la edad</h4>
              <ul><h4 className="text-yellow-500 text-xl"><b>Ingredientes Activos</b></h4> </ul> 
                    <li>Concentrado de Aloe Vera</li>
                    <li>Árbol del Té (Melaleuca alternifolia)</li>
                  <h4 className="font-bold text-yellow-500 text-xl">Otros Ingredientes</h4>
                    <li>Agua</li>
                    <li>Carbopol</li>
                    <li>Glicerina</li>
                    <li>Fenoxietanol</li>
                    <li>Fragancia Natural del Árbol del Té</li>
                  <h4 className="font-bold text-yellow-500 text-xl">Modo de uso</h4>
                    <p>Aplique una capa delgada del gel sore la piel que escoja, dando un suave masaje. También puede usarlo después del baño o por la noche.</p>
                  <h4 className="font-bold text-yellow-500 text-xl">Notas</h4>
                    <p>Libre de aceite, libre de Parabenos y libre de crueldad animal (Vegano)</p>
            </div>
            <div class="btnficha">
              <button className="font-bold text-yellow-50 text-lg"> <b> <a href={FichaTe} download='Ficha tecnica Arbol del Te.pdf'>  Descargar Ficha Técnica </a> </b> </button> 
            </div>
            <div>
              <button className="font-bold text-yellow-50 text-lg"><b> <a className="my-3" target="_blank" rel="noreferrer" href="https://www.amazon.com.mx/Hidrogel-%C3%81rbol-Cuerpo-Rostro-Masaje/dp/B09DGSJ7RY/ref=sr_1_12?m=A6KPL46OPBDX8&marketplaceID=A1AM78C64UM0Y8&qid=1639167048&s=merchant-items&sr=1-12">  Comprar en Amazon </a> </b> </button> 
            </div>
          </div>
        </div>

        {/* Ficha de Producto Aloe Vera con Acido Hialuronico */}
        <div className="cuadro rounded-lg">
          <div>
            <div>
              <img src={AloeVera} className="justify-items-center" alt="Gel Aloe Vera con Lavanda" width="300px"></img>
            </div>
            <div className="textocuadrado">
              <h2 className="text-yellow-500 text-3xl"> <b>Beuty Gel de Alore Vera & Ácido Hialuronico </b> </h2>
              <h4>Producto de cuidado en casa, recomendado para el apoyo de pieles secas y con signos de causados por la edad</h4>
              <ul><h4 className="text-yellow-500 text-xl"><b>Ingredientes Activos</b></h4> </ul>  
                    <li>Concentrado de Aloe Vera</li>
                    <li>Ácido Hialuronico de Origen Vegetal (1.5%)</li>
                  <h4 className="font-bold text-yellow-500 text-xl">Otros Ingredientes</h4>
                    <li>Agua</li>
                    <li>Carbopol</li>
                    <li>Glicerina</li>
                    <li>Fenoxietanol</li>
                    <li>Fragancia Natural del Árbol del Té</li>
                  <h4 className="font-bold text-yellow-500 text-xl">Modo de uso</h4>
                    <p>Aplique una capa delgada del gel sore la piel que escoja, dando un suave masaje. También puede usarlo después del baño o por la noche.</p>
                  <h4 className="font-bold text-yellow-500 text-xl">Notas</h4>
                    <p>Libre de aceite, libre de Parabenos y libre de crueldad animal (Vegano)</p>
            </div>
            <div class="btnficha">
              <button className="font-bold text-yellow-50 text-lg"> <b> <a href={FichaHialuronico} download='Ficha tecnica Aloe Vera con Hialuronico.pdf'>  Descargar Ficha Técnica </a> </b> </button> 
            </div>
            <div>
              <button className="font-bold text-yellow-50 text-lg"><b> <a className="my-3" target="_blank" rel="noreferrer" href="https://www.amazon.com.mx/Hidrogel-Hialur%C3%B3nico-Cuerpo-Rostro-Masaje/dp/B09DGSKJLV/ref=sr_1_11?m=A6KPL46OPBDX8&marketplaceID=A1AM78C64UM0Y8&qid=1639167048&s=merchant-items&sr=1-11">  Comprar en Amazon </a> </b> </button> 
            </div>
          </div>
        </div>

                {/* Ficha de Producto Lavanda */}
                <div className="cuadro rounded-lg">
          <div>
            <div>
              <img src={Lavanda} className="justify-items-center" alt="Gel Aloe Vera con Lavanda" width="300px"></img>
             </div>
             <div className="textocuadrado">
              <h2 className="text-yellow-500 text-3xl"> <b> Hydro Gel de Lavanda </b> </h2>
              <h4>Producto de cuidado en casa, recomendado para el apoyo de pieles secas y con signos de causados por la edad</h4>
              <ul><h4 className="font-bold text-yellow-500 text-xl"><b>Ingredientes Activos</b></h4> </ul>  
                    <li>Extracto concentrado de Lavanda</li>
                  <h4 className="font-bold text-yellow-500 text-xl">Otros Ingredientes</h4>
                    <li>Agua</li>
                    <li>Carbopol</li>
                    <li>Glicerina</li>
                    <li>Fenoxietanol</li>
                    <li>Fragancia Natural de Lavanda</li>
                  <h4 className="font-bold text-yellow-500 text-xl">Modo de uso</h4>
                    <p>Aplique una capa delgada del gel sore la piel que escoja, dando un suave masaje. También puede usarlo después del baño o por la noche.</p>
                  <h4 className="font-bold text-yellow-500 text-xl">Notas</h4>
                    <p>Libre de aceite, libre de Parabenos y libre de crueldad animal (Vegano)</p>
            </div>
            <div class="btnficha">
              <button className="font-bold text-yellow-50 text-lg"> <b><a href={FichaLavanda} download='Ficha tecnica Lavanda.pdf'>  Descargar Ficha Técnica </a> </b> </button> 
            </div>
            <div>
              <button className="font-bold text-yellow-50 text-lg"><b> <a className="my-3" target="_blank" rel="noreferrer" href="https://www.amazon.com.mx/Hidrogel-Lavanda-Cuerpo-Rostro-Masaje/dp/B09FF1Q1LH/ref=sr_1_10?m=A6KPL46OPBDX8&marketplaceID=A1AM78C64UM0Y8&qid=1639167048&s=merchant-items&sr=1-10">  Comprar en Amazon </a>  </b> </button>
            </div>         
          </div>
        </div>

        {/* Ficha de Producto Arnica */}
        <div className="cuadro rounded-lg">
          <div>
            <div >
              <img src={Arnica} className="justify-items-center" alt="Gel Aloe Vera con Lavanda" width="300px"></img>
            </div>
            <div className="textocuadrado">
              <h2 className="text-yellow-500 text-3xl"> <b> Gel de Árnica con Manzanilla </b> </h2>
              <h4>Producto de cuidado en casa, recomendado para el apoyo de pieles secas y con signos de causados por la edad</h4>
              <ul><h4 className="font-bold text-yellow-500 text-xl"><b>Ingredientes Activos</b></h4> </ul> 
                    <li>Extracto concentrado de Árnica</li>
                    <li>Extracto concentrado de Manzanilla</li>
                  <h4 className="font-bold text-yellow-500 text-xl">Otros Ingredientes</h4>
                    <li>Agua</li>
                    <li>Carbopol</li>
                    <li>Glicerina</li>
                    <li>Fenoxietanol</li>
                    <li>Fragancia Natural</li>
                  <h4 className="font-bold text-yellow-500 text-xl">Modo de uso</h4>
                    <p>Aplique una capa delgada del gel sore la piel que escoja, dando un suave masaje. También puede usarlo después del baño o por la noche.</p>
                  <h4 className="font-bold text-yellow-500 text-xl">Notas</h4>
                    <p>Libre de aceite, libre de Parabenos y libre de crueldad animal (Vegano)</p>
            </div>
            <div class="btnficha">
              <button className="font-bold text-yellow-50 text-lg"> <b> <a href={FichaArnica} download='Ficha tecnica Arnica.pdf'>  Descargar Ficha Técnica </a> </b> </button> 
            </div>
            <div>
              <button className="font-bold text-yellow-50 text-lg"><b> <a className="my-3" target="_blank" rel="noreferrer" href="https://www.amazon.com.mx/Hidrogel-Manzanilla-Cuerpo-Rostro-Masaje/dp/B09DGQFTT3/ref=sr_1_13?m=A6KPL46OPBDX8&marketplaceID=A1AM78C64UM0Y8&qid=1639167048&s=merchant-items&sr=1-13"> Comprar en Amazon </a> </b>  </button>
            </div>
          </div>
        </div>

        
        {/* Ficha de Producto Almendra Dulce */}
        <div className="cuadro rounded-lg">
          <div>
            <div>
              <img src={Almendra} className="" alt="Gel Aloe Vera con Lavanda" width="300px"></img>
            </div>
            <div className="textocuadrado">
              <h2 className="text-yellow-500 text-3xl"> <b> Gel Hidratante de Almendra Dulce </b> </h2>
              <h4>Producto de cuidado en casa, recomendado para el apoyo de pieles secas y con signos de causados por la edad</h4>
              <ul><h4 className="font-bold text-yellow-500 text-xl"><b>Ingredientes</b></h4> </ul>  
                    <li>Aceite mineral</li>
                    <li>Aceite de Durazno</li>
                    <li>Fragancia natural de almendra</li>
                  <h4 className="font-bold text-yellow-500 text-xl">Modo de uso</h4>
                    <p>Aplique una capa delgada del gel sore la piel que escoja, dando un suave masaje. También puede usarlo después del baño o por la noche.</p>
                  <h4 className="font-bold text-yellow-500 text-xl">Notas</h4>
                    <p>Libre de aceite, libre de Parabenos y libre de crueldad animal (Vegano)</p>
            </div>
            <div class="btnficha">
              <button className="font-bold text-yellow-50 text-lg"> <b> <a href={FichaAlmendra} download='Ficha tecnica Almendra Dulce.pdf'>  Descargar Ficha Técnica </a> </b> </button> 
            </div>
              <div>
                <button className="font-bold text-yellow-50 text-lg"><b> <a  target="_blank" rel="noreferrer" href="https://www.amazon.com.mx/Hidroaceite-Almendras-Dulces-Cuerpo-Rostro/dp/B09D8FLZXP/ref=sr_1_14?m=A6KPL46OPBDX8&marketplaceID=A1AM78C64UM0Y8&qid=1639167048&s=merchant-items&sr=1-14"> Comprar en Amazon </a> </b>  </button>
              </div>
          </div>
        </div>
      
      </section>
    </div>
  )
}

function Conferencias() {

  const [videos, setVideos] = useState([]);

  useEffect(() => {
    fetch(
      "https://www.googleapis.com/youtube/v3/search?key=AIzaSyBB2cosHX3pchrzeb4ky2wAdB1QDCeOUcY&channelId=UCzRnVDScAvo6GaXmGV62jyQ&part=snippet,id&order=date&maxResults=10"
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data.items);
        setVideos(data.items);
      })
      .catch((error) => console.log(error));
  }, []);



  return(
    <>
      <nav>
        <Link className="hover:text-yellow-500" to="/">Home</Link>
        <Link className="hover:text-yellow-500" to="/productos">Productos</Link>
      </nav>
      <section className="background">
        <h2 className="background text-4xl text-yellow-500 md:text-7xl font-bold pt-4">Nuestras Conferencias</h2>
        {videos &&
          videos.map((video) => {
            return (
                <div className="background" >
                  <div className="min-h-screen backrgound py-6 flex flex-col justify-center sm:py-12">
                    <div className="relative py-3 w-11/12 sm:mx-auto ">
                      <div className="absolute inset-0 bg-gradient-to-r from-yellow-400 via-red-500 to-pink-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl ml-12"></div>
                      <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20  ml-12">
                        <h1 className="text-3xl text-yellow-500 pb-8 -mt-8 font-semibold">{video.snippet.title}</h1>
                        <iframe className=""
                            title={video.snippet.title}
                            width="100%"
                            height="500px"
                            allowFullScreen
                            src={`https://www.youtube.com/embed/${video.id.videoId}`}
                        >
                        </iframe>
                          <h3 className="pb-3 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7 mt-8 font-medium ">{video.snippet.description}</h3>
                    </div>
                  </div>
                </div>
                  <div>
                  </div>
                </div>
            );
          })}
          <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCzRnVDScAvo6GaXmGV62jyQ/null">
            <button className="font-bold bg-amber-50 text-yellow-50 text-lg mb-8">Ver todos los videos en Youtube</button>
          </a>
      </section>
      
    </>
  )
}

  return (
    <div className="App">
      <Routes>   
          <Route exact path="/" element={<Home />} />
          <Route path="productos" element={<Productos />} />
          <Route path="conferencias" element={<Conferencias />} />
      </Routes>

    </div>
  );
}

export default App;
